import type { Location } from "@solidjs/router";

export const urls = {
  thread: (projectId: string, threadId: string, scrollTo?: string) =>
    `/project/${projectId}/thread/${threadId}${scrollTo ? `?scrollTo=${scrollTo}` : ""}`,
  homepage: () => "/",
  collection: (
    collectionId: string,
    params?: Partial<{
      assetId?: string;
    }>,
  ) => {
    const query = new URLSearchParams();
    if (params?.assetId) {
      query.set("assetId", params.assetId);
    }
    return `/app/collection/${collectionId}${query.toString() ? `?${query.toString()}` : ""}`;
  },
  threadV2: (threadId: string) => `/app/thread/${threadId}`,
  acceptInvite: (token: string) => `/app/invite/accept?token=${token}`,
  logIn: (token?: string) => `/auth/login${token ? `?invite=${token}` : ""}`,
  signUp: (token?: string) => `/auth/signup${token ? `?invite=${token}` : ""}`,
};

export const isHomePage = (location: Location<unknown>) => location.pathname === urls.homepage();

/**
 * Rebuilds the full path from a location object.
 * @param location - The location object returned from `useLocation()`
 * @returns {string}
 * @example
 * ```ts
 * const location = useLocation();
 * const fullPath = rebuildHref(location);
 * ```
 */
export const rebuildFullPath = (location: Location): string => {
  const search = location.search && location.search !== "?" ? location.search : "";
  const hash = location.hash && location.hash !== "#" ? location.hash : "";
  return location.pathname + search + hash;
};
